
const WhatsAppIco = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 46" fill="#252525"  {...props}>
        <g id="a" />
        <g id="b">
            <g id="c">
                <path
                    d="M46,23.02c.01,6.98-3.17,13.58-8.64,17.91-3.83,3.14-8.6,4.91-13.55,5.04-4.38,.19-8.72-.91-12.48-3.17-.56-.4-1.28-.51-1.93-.29-2.38,.76-4.8,1.41-7.2,2.1-.25,.07-.54,.26-.77,.04-.23-.23,0-.51,.1-.75,.85-2.25,1.7-4.5,2.57-6.74,.2-.37,.17-.83-.08-1.17C1.41,32.23,0,27.77,0,23.2c-.07-4.62,1.29-9.15,3.9-12.97C6.89,5.66,11.46,2.34,16.74,.92,23.22-1,30.22,.11,35.79,3.94c4.56,3.01,7.88,7.56,9.34,12.83,.54,2.04,.83,4.14,.88,6.25m-15.88,12.66c.92,.12,1.84-.21,2.48-.88,.74-.66,1.45-1.36,2.14-2.06,1.09-.92,1.23-2.55,.31-3.64-.09-.11-.19-.21-.3-.3-1.02-1.04-2.06-2.08-3.1-3.11-.76-1.01-2.19-1.21-3.2-.45-.39,.29-.67,.7-.81,1.16-.43,1.26-1.8,1.93-3.06,1.5-.06-.02-.12-.05-.18-.07-2.94-1.11-5.27-3.42-6.39-6.35-.4-1.27,.23-2.64,1.46-3.16,1.19-.3,1.91-1.5,1.61-2.69-.12-.46-.38-.87-.74-1.17-1.1-1.19-2.25-2.31-3.43-3.4-.91-.94-2.4-.97-3.34-.06-.06,.05-.11,.11-.16,.17-.72,.69-1.4,1.4-2.11,2.1-.58,.58-.92,1.37-.94,2.19-.06,1.77,.36,3.52,1.23,5.07,2.94,5.76,7.55,10.5,13.21,13.62,1.61,.98,3.45,1.51,5.33,1.53"
                />
            </g>
        </g>
    </svg >
);

export default WhatsAppIco;