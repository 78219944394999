
const InstagramSvg = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" height="21"  viewBox="0 0 37 37.01" {...props}>
        <g id="a" />
        <g id="b">
            <g id="c">
                <g>
                    <path
                        className="d"
                        d="M36.99,18.51c0,2.93,.01,5.86,0,8.79-.02,2.35-.9,4.61-2.5,6.34-1.87,2.22-4.66,3.46-7.56,3.36-5.62,0-11.25-.02-16.87,.01-2.66,.06-5.23-.99-7.1-2.88C1.1,32.39,.03,29.95,.02,27.4,0,21.47,0,15.55,.02,9.62c.02-2.3,.9-4.51,2.46-6.2C4.04,1.6,6.21,.41,8.58,.08,9.07,.02,9.57,0,10.07,.01,15.69,0,21.32,.03,26.93,0c2.66-.06,5.23,.99,7.11,2.88,1.86,1.75,2.92,4.18,2.95,6.73,.03,2.96,0,5.93,0,8.89m-34.72,.57c.06,2.47-.15,5.55,.09,8.62,.18,2.62,1.74,4.95,4.09,6.12,1.19,.63,2.53,.95,3.89,.91h15.34c.69,.01,1.37-.01,2.06-.07,2.15-.2,4.12-1.3,5.41-3.04,1.08-1.42,1.64-3.16,1.59-4.94V11.25c.01-.65-.01-1.31-.07-1.96-.19-2.19-1.33-4.19-3.11-5.47-1.4-1.04-3.11-1.58-4.85-1.54H11.21c-.64-.01-1.28,.01-1.92,.07-2.21,.19-4.21,1.34-5.5,3.14-1.03,1.39-1.56,3.09-1.52,4.82v8.78"
                    />
                    <path
                        className="d"
                        d="M18.49,28.64c-5.61-.02-10.14-4.56-10.16-10.16,.02-5.61,4.58-10.15,10.2-10.13,5.61,.02,10.15,4.58,10.13,10.2-.05,5.59-4.59,10.09-10.17,10.1m7.86-10.15c0-4.33-3.52-7.84-7.86-7.84-4.33,0-7.84,3.52-7.84,7.86,0,4.33,3.51,7.83,7.83,7.84,4.31,.03,7.83-3.44,7.86-7.75v-.1"
                    />
                    <path
                        className="d"
                        d="M25.79,7.77c0-1.68,1.37-3.04,3.06-3.04,1.68,0,3.04,1.37,3.04,3.06,.03,1.68-1.3,3.08-2.99,3.11-1.68,.03-3.08-1.3-3.11-2.99,0-.05,0-.1,0-.14m3.75,.05c.01-.38-.29-.7-.67-.71-.02,0-.04,0-.05,0-.37,0-.67,.3-.68,.67-.02,.38,.26,.7,.64,.72,.02,0,.05,0,.07,0,.35,.03,.66-.23,.69-.59,0-.03,0-.06,0-.09"
                    />
                </g>
            </g>
        </g>
    </svg >
);

export default InstagramSvg;